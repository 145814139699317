.loading-questions {
  max-width: 100%;
}

.question-animate {
  animation: slideIn 0.5s ease-out forwards;
}

.continue-animate {
  animation: fadeIn 0.5s ease-in forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loading-questions .p-button.p-button-outlined {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.loading-questions .p-button.p-button-outlined:hover {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.3);
}