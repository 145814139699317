.login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
}

.p-field {
    margin-bottom: 1rem;
}

.p-field label {
    display: block;
    margin-bottom: 0.5rem;
}

.p-icon-field {
    width: 100%;
}

.p-inputtext {
    width: 100%;
}