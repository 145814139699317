:root {
  --cardW: 14em;
  --cardH: 8em;
  --cardZInc: 2em;
  --gridSize: 4;  /* default value */
}

.icc-cards {
  will-change: transform;
  display: grid;
  grid-template: repeat(var(--gridSize), var(--cardH)) / repeat(var(--gridSize), var(--cardW));
  grid-gap: 1.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotateX(45deg) rotateZ(45deg) translateY(var(--scroll));
  -webkit-transform: translate(-50%, -50%) rotateX(45deg) rotateZ(45deg) translateY(var(--scroll));
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}


.wrapper {
  margin-left: auto;
  margin-right: auto;
  border-radius: 30px;
  width: min(85vw, 850px);
  height: 400px;
  position: relative;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.icc-main {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 30px;
}

.icc-stack {
  display: block;
  position: relative;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}
 
.icc-contents {
  position: absolute;
  bottom: 10px;
  color: white;
  font-size: 0.5em;
  line-height: 1.25;
}

.icc-stack:nth-of-type(even) .icc-contents {
  color: #fff;
}

.icc-contents h1 {
  font-family: 'cc font', monospace;
  font-size: 1.5em;
  font-weight: 400;

}

.icc-contents h2 {
  font-size: 1em;
  font-weight: 400;
}

.icc-contents span {
  margin-right: 0.75em;
}

.icc-stack:nth-of-type(16n + 1) .icc-card { background-position: 0 0; }
.icc-stack:nth-of-type(16n + 2) .icc-card { background-position: 0 100%; }
.icc-stack:nth-of-type(16n + 3) .icc-card { background-position: 0 200%; }
.icc-stack:nth-of-type(16n + 4) .icc-card { background-position: 0 300%; }
.icc-stack:nth-of-type(16n + 5) .icc-card { background-position: 100% 0; }
.icc-stack:nth-of-type(16n + 6) .icc-card { background-position: 100% 100%; }
.icc-stack:nth-of-type(16n + 7) .icc-card { background-position: 100% 200%; }
.icc-stack:nth-of-type(16n + 8) .icc-card { background-position: 100% 300%; }
.icc-stack:nth-of-type(16n + 9) .icc-card { background-position: 200% 0; }
.icc-stack:nth-of-type(16n + 10) .icc-card { background-position: 200% 100%; }
.icc-stack:nth-of-type(16n + 11) .icc-card { background-position: 200% 200%; }
.icc-stack:nth-of-type(16n + 12) .icc-card { background-position: 200% 300%; }
.icc-stack:nth-of-type(16n + 13) .icc-card { background-position: 300% 0; }
.icc-stack:nth-of-type(16n + 14) .icc-card { background-position: 300% 100%; }
.icc-stack:nth-of-type(16n + 15) .icc-card { background-position: 300% 200%; }
.icc-stack:nth-of-type(16n + 16) .icc-card { background-position: 300% 300%; }

.icc-stack:hover .top,
.icc-stack:focus .top {
  transform: translateZ(calc(var(--cardZInc) * 3));
  -webkit-transform: translateZ(calc(var(--cardZInc) * 3));
}

.icc-stack:hover .mid,
.icc-stack:focus .mid {
  transform: translateZ(calc(var(--cardZInc) * 2));
  -webkit-transform: translateZ(calc(var(--cardZInc) * 2));
}

.icc-stack:hover .bottom,
.icc-stack:focus .bottom {
  transform: translateZ(var(--cardZInc));
}

.icc-card {
  background-image: url("icc.png");
  background-size: 400% 400%;
  border-radius: 9px;
  box-shadow: -1px -1px 0 hsla(0,0%,0%,0.2) inset;
  color: #000;
  padding-left: 0.5em;
  position: absolute;
  transition: all 0.3s;
  width: 100%;
  height: 100%;
}

.icc-card.top {
  transform: translateZ(3px);
  -webkit-transform: translateZ(3px);
  z-index: 3;
}

.icc-card.mid {
  transform: translateZ(2px);
  -webkit-transform: translateZ(2px);
  z-index: 2;
}

.icc-card.bottom {
  transform: translateZ(1px);
  -webkit-transform: translateZ(1px);
  z-index: 1;
}